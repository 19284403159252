import { getServer } from "../../utils/Environment";

const server = getServer();

const url = server + "/server/server.php";
// const urlMonthly = server + "/server/statistics/statistics-monthly.php";
const messageUrl = server + "/server/server.php";
export async function sendTGMessage(message) {
  try {
    const result = await fetch(messageUrl, {
      method: "POST",
      body: JSON.stringify({ message, method: "sendTGMessage" }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    console.log(error);
    // throw error;
    // return false;
  }
}
