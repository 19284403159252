import "./App.css";
import { Routes, Route } from "react-router-dom";
import Mainpage from "./pages/Mainpage";
import Layout from "./components/Layout";
import Nopage from "./pages/Nopage";
import Createuser from "./pages/Createuser";
import TradeAgents from "./pages/TradeAgents";
import Loginpage from "./pages/Loginpage";
import RequireAuth from "./hoc/RequireAuth";
import { AuthProvider } from "./hoc/AuthProvider";
import MultipleEdit from "./pages/MultipleEdit";
import WaappStatistics from "./pages/WaappStatistics";
import { useEffect } from "react";
import Payments from "./pages/Payments";
import Companies from "./pages/Companies";

function App() {
  useEffect(() => {
    document.body.style.backgroundColor = "#202124";
  }, []);

  return (
    <>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Layout></Layout>
              </RequireAuth>
            }
          >
            <Route index element={<Mainpage />} />
            <Route path="waapp-statistics" element={<WaappStatistics />} />
            <Route path="add-user" element={<Createuser />} />
            <Route path="trade-agents" element={<TradeAgents />} />
            <Route path="payments" element={<Payments />} />
            <Route path="companies" element={<Companies />} />
            <Route path="multiple-edit-users" element={<MultipleEdit />} />
            <Route path="*" element={<Nopage />} />
          </Route>
          <Route path="/login" element={<Loginpage></Loginpage>}></Route>
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
