import React, { useEffect, useState } from "react";

import { Modal, Spin, Tabs } from "antd";
import { getTaUserById, getUserById } from "../api/db-api";
import { User } from "../../CONSTANTS/interfaces";
import { getFtpUserAccountByUsername } from "../api/hosting-api/functions";
import EditTAForm from "./EditTAForm";

interface ModalEditUserProps {
  user: User;
  isModalEditTAOpen: boolean;
  setIsModalEditTAOpen: Function;
  setTradeAgents: Function;
  companies: [];
}

export default function ModalEditTa({
  user,
  setTradeAgents,
  isModalEditTAOpen,
  setIsModalEditTAOpen,
  companies,
}: ModalEditUserProps) {
  const [editingUser, setEditingUser] = useState<any>();

  useEffect(() => {
    if (user) getData(user?.id);

    return () => {
      setEditingUser(undefined);
    };
  }, [user?.id]);

  const getData = async (id: number) => {
    const userObj = await getTaUserById(id);
    console.log(userObj);
    // const result = await getFtpUserAccountByUsername(userObj.login);
    // if (result.result) {
    // userObj.ftpUserId = result.response.ftp_id;
    // }
    setEditingUser(userObj);
  };

  const handleCancel = () => {
    setIsModalEditTAOpen(false);
  };

  const items = [
    {
      key: "1",
      label: `Дані`,
      children: (
        <EditTAForm
          user={editingUser}
          setTradeAgents={setTradeAgents}
          setIsModalEditTAOpen={setIsModalEditTAOpen}
          companies={companies}
        ></EditTAForm>
      ),
    },
  ];

  return (
    <div>
      <Modal
        title={"Торговий: " + user?.login}
        open={isModalEditTAOpen}
        styles={{ header: { textAlign: "center" } }}
        footer={null}
        onCancel={handleCancel}
        width={"90%"}
      >
        {!editingUser ? (
          <Spin spinning={true}></Spin>
        ) : (
          <Tabs defaultActiveKey="1" items={items}></Tabs>
        )}
      </Modal>
    </div>
  );
}
