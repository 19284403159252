import {
  Col,
  Row,
  Form,
  message,
  Input,
  Select,
  DatePicker,
  Button,
} from "antd";
import { addCompany } from "../api/db-api";

export default function AddCompanyForm({ companies, setCompanies }) {
  const [form] = Form.useForm();

  // useEffect(() => {
  //   form.setFieldsValue({
  //     date: dayjs().format(PAYMENT_DATE_FORMAT),
  //   });
  // }, []);

  const onFinish = async (values: any) => {
    const response = await addCompany(values.companyName, values.info);
    if (response.status === "error") return message.error(response.message);

    setCompanies((prevPayments) => {
      return [
        ...prevPayments,
        {
          key: companies.length + 1,
          company: { name: values.companyName },
        },
      ];
    });
    message.success("Успішно!");
    // const newRecord = {
    //   key: payments.length + 1,
    //   sum: values.sum,
    //   date: values.date.format(PAYMENT_DATE_FORMAT),
    // };
    // // setPayments(
    // //   [newRecord, ...payments].sort(
    // //     (a, b) => new Date(b.date) - new Date(a.date)
    // //   )
    // // );
  };
  const onFinishFailed = (errorInfo: object) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        layout="horizontal"
        name="paymentForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ justifyContent: "center" }}
        wrapperCol={{ span: 12 }}
        form={form}
      >
        <Row>
          <Col span={10}>
            <Form.Item
              label="Назва компанії"
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please input your companyName!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Додатково"
              name="info"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Додати
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
