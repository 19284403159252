import { useEffect, useState } from "react";
import { getUsers } from "../components/api/db-api";
import UsersTable from "../components/mainpage/UsersTable";
import { useLocation } from "react-router";
import { User } from "../components/types/user";

export default function Mainpage() {
  const location = useLocation();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUpdateTableNeeded] = useState(
    location?.state?.updateTable ? true : false
  );

  useEffect(() => {
    const getData = async () => {
      const usersFromDb = await getUsers();
      const users = usersFromDb.map((user: User, index: number) => {
        user.key = index + 1;
        return user;
      });
      setUsers(users);
      setLoading(false);
    };
    getData();
  }, [isUpdateTableNeeded]);

  return (
    <>
      <UsersTable
        loading={loading}
        users={users}
        setUsers={setUsers}
      ></UsersTable>
    </>
  );
}
