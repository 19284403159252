import { useRef, useState } from "react";
import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import { Table, Input, Space, Button, DatePicker } from "antd";
import { getColumns, getTableSummary } from "./columns";

export default function DaylyStatistics({ statisticsData, loading }) {
  const [setSearchText] = useState();
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys: Array<string>, confirm: Function) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size={"large"}
          ref={searchInput}
          placeholder={`Введіть текст`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Шукати
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Очистити
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const onRowAction = (record, rowIndex) => {
    return {
      onClick: (event) => {
        // setIsModalActionsOpen(true);
        // setUser(record);
      },
    };
  };

  return (
    <div>
      <Table
        scroll={{
          y: window.screen.height - window.screen.height / 3,
        }}
        summary={() => getTableSummary(statisticsData)}
        loading={loading}
        onRow={onRowAction}
        columns={getColumns()}
        dataSource={statisticsData}
        bordered={true}
        pagination={{ position: ["bottomCenter"], showSizeChanger: true }}
      />
    </div>
  );
}
