import { Dropdown, Popconfirm } from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";

export function RecordActionsMenu(props) {
  const dropdownItems = [
    {
      label: "Змінити",
      key: "edit",
      icon: <EditOutlined />,
      onClick: () => props.onEdit(props.record),
    },
    {
      label: (
        <Popconfirm
          title="Видалити рядок?"
          onConfirm={() => props.onDelete(props.record)}
          onCancel={() => {}}
          okText="Так"
          cancelText="Ні"
        >
          <span onClick={(e) => e.stopPropagation()}>Видалити</span>
        </Popconfirm>
      ),
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <Dropdown
      icon={<DownOutlined />}
      menu={{ items: dropdownItems }}
      trigger={["click"]}
    >
      <MenuFoldOutlined />
    </Dropdown>
  );
}
