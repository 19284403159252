import { Button, Input, Modal, Form, message } from "antd";
import { checkBlockIp, unblockIp } from "../api/hosting-api/functions";

export default function ModalCheckIpBlock({
  isModalCheckIpBlockOpen,
  setIsModalCheckIpBlockOpen,
}) {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const ip = values.ip;
    const res = await checkBlockIp(ip);
    if (!res?.result) {
      console.log("Помилка виконання запиту");
      return message.error("Помилка виконання запиту");
    }
    if (!res.response.is_block) {
      return message.info("Іп адреса не заблокована");
    }

    Modal.confirm({
      title: "Іп адреса заблокована",
      type: "warning",
      okText: "Розблокувати",
      cancelText: "Закрити",
      onCancel: () => setIsModalCheckIpBlockOpen(false),
      onOk: () => unblockIpFuncion(ip),
    });
  };

  const unblockIpFuncion = async (ip) => {
    const unblockRes = await unblockIp(ip);
    if (!unblockRes?.result) {
      console.log("Помилка виконання запиту");
      return message.error("Помилка виконання запиту");
    }
    if (!unblockRes.response.is_block) {
      return message.info("Іп адреса не заблокована");
    }
  };

  const onFinishFailed = (errorInfo: object) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Modal
        title={"Перевірка блокування іп адреси"}
        open={isModalCheckIpBlockOpen}
        styles={{ header: { textAlign: "center" } }}
        footer={null}
        onCancel={() => setIsModalCheckIpBlockOpen(false)}
        width={"50%"}
      >
        <Form
          layout="vertical"
          name="checkIpBlock"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ justifyContent: "center", textAlign: "center" }}
          form={form}
        >
          <Form.Item
            label="Іп адреса"
            name="ip"
            max
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input maxLength={15} />
          </Form.Item>
          <Form.Item style={{ justifyContent: "center" }}>
            <Button size="large" type="primary" htmlType="submit">
              Перевірити
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
