import { Button, Form, Input, message, Space } from "antd";
import { updateUserPaymentSum } from "../api/payments/functions";
import { useEffect } from "react";

export default function PaymentForm({ user, setIsModalEditUserOpen }) {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const response = await updateUserPaymentSum(values.sum, user.id);
    if (response.status === "error") return message.error(response.message);

    setIsModalEditUserOpen(false);
    message.success("Оновлено успішно");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue({ sum: user?.paymentSum });
  }, [user]);

  return (
    <div>
      <Form
        layout="vertical"
        name="paymentForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ justifyContent: "center" }}
        form={form}
      >
        <Space direction="vertical>">
          <Form.Item
            label="Сума"
            name="sum"
            rules={[
              {
                required: true,
                message: "Please input sum!",
              },
            ]}
          >
            <Input allowClear={true} />
          </Form.Item>
        </Space>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
