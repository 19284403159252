import { createContext, useState } from "react";

export const AuthContext = createContext(null);

// var sessionStorage = Storage;

export const AuthProvider = ({ children }) => {
  const loggedInUser =
    sessionStorage.getItem("user") === undefined
      ? null
      : JSON.parse(sessionStorage.getItem("user"));

  const [user, setUser] = useState(loggedInUser);

  const signIn = (newUser: object, cb: Function) => {
    setUser(newUser);
    cb();
  };
  const signOut = (cb: Function) => {
    setUser(null);
    sessionStorage.removeItem("user");
    cb();
  };

  const value = { user, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
