import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useAuth } from "../hook/useAuth";
import { Form, Input, Button } from "antd";
import { login } from "../components/api/db-api";
import { message } from "antd";
import "../UI/css/Loginpage.css";
import { sendTGMessage } from "../components/api/telegram/telegram";
import { useState } from "react";

const Loginpage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isWaitingEnterCode, setIsWaitingEnterCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState<number>();
  const { user, signIn } = useAuth();
  if (user) {
    message.success("Ви вже авторизовані!");
    return <Navigate to="/"></Navigate>;
  }
  const fromPage = location.state?.from?.pathname || "/";

  function getRandomTwoDigitNumber() {
    return Math.floor(Math.random() * 90) + 10; // Генеруємо число від 10 до 99
  }

  const onFinish = async (values) => {
    const username = values.username;
    const password = values.password;
    try {
      const result = await login(username, password);

      if (result.status === "success") {
        const code = getRandomTwoDigitNumber();
        setVerificationCode(code);
        const response = await sendTGMessage(`Код для авторизації: ${code}`);
        if (response === false) {
          return message.error("Помилка надсилання коду");
        }

        setTimeout(() => {
          if (isWaitingEnterCode) {
            setIsWaitingEnterCode(false);
            return message.info("Час очікування вийшов!");
          }
        }, 60000);
        setIsWaitingEnterCode(true);
      } else {
        return message.error(result.message);
      }
    } catch (error) {
      return message.error("Невідома помилка...");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishEnterCode = (values) => {
    setIsWaitingEnterCode(false);
    if (+values.code === +verificationCode) {
      const user = { username: "admin" };
      sessionStorage.setItem("user", JSON.stringify(user));
      message.success("Cвій чоловік");
      return signIn(user, () => navigate(fromPage, { replace: true }));
    }
    return message.error("Щось ти не те ввів!");
  };

  return (
    <>
      <div className="loginPage">
        {isWaitingEnterCode && (
          <>
            <Form
              className="loginForm"
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinishEnterCode}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Код"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Введіть логін!",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item>
                <Button size="large" block type="primary" htmlType="submit">
                  Йо
                </Button>
              </Form.Item>
            </Form>
          </>
        )}

        {!isWaitingEnterCode && (
          <Form
            className="loginForm"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Логін"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Введіть логін!",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              label="Пароль"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Введіть пароль!",
                },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item>
              <Button size="large" block type="primary" htmlType="submit">
                Вхід
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </>
  );
};

export default Loginpage;
