import { message, Popconfirm, Table } from "antd";
import { getDataFromServer } from "../api/db-api";
import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";

export default function ExpectedPayments() {
  const [payments, setPayments] = useState([]);
  const [taPayments, setTaPayments] = useState([]);

  const [activeCompanyUsers, setActiveCompanyUsers] = useState([]);
  const [activeTaCompanyUsers, setTaActiveCompanyUsers] = useState([]);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [taExpandedRowKeys, setTaExpandedRowKeys] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await getDataFromServer("get-expected-payments");
    if (response.status === "error") return message.error(response.message);

    const data = response.data;
    const dataToTable = data.map((record) => {
      return { ...record, key: record.companyId };
    });
    setPayments(dataToTable);

    const responseTa = await getDataFromServer("get-ta-expected-payments");
    if (responseTa.status === "error") return message.error(responseTa.message);

    const TaData = responseTa.data;
    const taDataToTable = TaData.map((record) => {
      return { ...record, key: record.companyId };
    });
    setTaPayments(taDataToTable);
  };

  const columns = [
    {
      title: "Компанія",
      dataIndex: "companyName",
      key: "companyName",
      align: "center",
      render: (_, record) => record.companyName,
    },
    {
      title: "К-ть користувачів",
      dataIndex: "userCount",
      key: "userCount",
      align: "center",
      render: (_, record) => record.userCount,
    },
    {
      title: "Очікувана оплата",
      dataIndex: "expectedPayment",
      key: "expectedPayment",
      align: "center",
      sorter: (a, b) => a.expectedPayment - b.expectedPayment,
      defaultSortOrder: "descend",
    },
    {
      title: "Сума",
      dataIndex: "expectedPayment",
      key: "expectedPayment",
      align: "center",
      sorter: (a, b) => a.expectedPayment - b.expectedPayment,
      render: (_, record) =>
        record.companyName === "Продажа Техніки"
          ? record.expectedPayment
          : record.expectedPayment / 2,
    },
  ];

  const expandedRowRender = (record) => {
    const childColumns = [
      {
        title: "Користувач",
        dataIndex: "userLogin",
        key: "userLogin",
      },
      {
        title: "Сума",
        dataIndex: "paymentSum",
        key: "paymentSum",
      },
      {
        title: <EditOutlined />,
        key: "remove",
        dataIndex: "user_id",
        width: "10%",
        render: (_, record) => {
          return (
            <Popconfirm title={"Редагувати?"} onConfirm={() => {}}>
              <EditOutlined />
            </Popconfirm>
          );
        },
      },
    ];

    return (
      <Table
        columns={childColumns}
        dataSource={activeCompanyUsers}
        pagination={false}
        size="small"
        tableLayout="fixed"
      />
    );
  };

  const onTableRowExpand = (expanded, record) => {
    setExpandedRowKeys(expanded ? [record.key] : []);

    setActiveCompanyUsers(
      record.userDetails.map((user) => ({
        ...user,
        key: user.login,
      }))
    );
  };

  const tableSummary = () => {
    let totalAmount = 0;
    let totalPay = 0;
    payments.forEach(({ expectedPayment, companyName }) => {
      totalPay +=
        companyName === "Продажа Техніки"
          ? +expectedPayment
          : +expectedPayment / 2;
      totalAmount += +expectedPayment;
    });

    return (
      <Table.Summary.Row style={{ textAlign: "center", fontWeight: "bold" }}>
        <Table.Summary.Cell index={0}></Table.Summary.Cell>
        <Table.Summary.Cell index={1}></Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
        <Table.Summary.Cell
          index={4}
        >{`Загалом: ${totalAmount} грн`}</Table.Summary.Cell>
        <Table.Summary.Cell
          index={4}
        >{`Сума: ${totalPay} грн`}</Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  const taTableSummary = () => {
    let totalAmount = 0;
    let totalPay = 0;
    taPayments.forEach(({ expectedPayment, companyName }) => {
      totalPay +=
        companyName === "Продажа Техніки"
          ? +expectedPayment
          : +expectedPayment / 2;
      totalAmount += +expectedPayment;
    });

    return (
      <Table.Summary.Row style={{ textAlign: "center", fontWeight: "bold" }}>
        <Table.Summary.Cell index={0}></Table.Summary.Cell>
        <Table.Summary.Cell index={1}></Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
        <Table.Summary.Cell
          index={4}
        >{`Загалом: ${totalAmount} грн`}</Table.Summary.Cell>
        <Table.Summary.Cell
          index={4}
        >{`Сума: ${totalPay} грн`}</Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={payments}
        summary={tableSummary}
        pagination={{ pageSize: 999, hideOnSinglePage: true }}
        expandable={{
          expandedRowRender, // Відображення розгорнутого рядка
          expandRowByClick: true, // Дозволяємо розгортання по кліку
          onExpand: onTableRowExpand, // Обробник події розгортання
          expandedRowKeys, // Відстежуємо, які рядки розгорнуті
          rowExpandable: (record) => record?.userDetails, // Дозволяємо розгортання тільки для тих рядків, які мають користувачів
        }}
      />

      <Table
        columns={columns}
        dataSource={taPayments}
        summary={taTableSummary}
        pagination={{ pageSize: 999, hideOnSinglePage: true }}
        expandable={{
          expandedRowRender, // Відображення розгорнутого рядка
          expandRowByClick: true, // Дозволяємо розгортання по кліку
          onExpand: onTableRowExpand, // Обробник події розгортання
          expandedRowKeys, // Відстежуємо, які рядки розгорнуті
          rowExpandable: (record) => record?.userDetails, // Дозволяємо розгортання тільки для тих рядків, які мають користувачів
        }}
      />
    </div>
  );
}
