import { UserOutlined } from "@ant-design/icons";
import { Table } from "antd";

export const getColumns = () => [
  {
    title: <UserOutlined />,
    dataIndex: "username",
    key: "username",
    align: "center",
    sorter: (a, b) => {
      return a.username.localeCompare(b.username);
    },
    //   ...getColumnSearchProps("username"),
  },
  {
    title: "Ревізії",
    dataIndex: "revizia",
    key: "revisions",
    align: "center",
    sorter: (a, b) => a.revizia - b.revizia,
  },
  {
    title: "Приходи",
    dataIndex: "invoice",
    key: "invoices",
    align: "center",
    sorter: (a, b) => a.invoice - b.invoice,
  },
  {
    title: "Замовлення",
    dataIndex: "order",
    key: "orders",
    align: "center",
    sorter: (a, b) => a.order - b.order,
  },
  {
    title: "Повернення",
    dataIndex: "returns",
    align: "center",
    sorter: (a, b) => a.returns - b.returns,
  },
  {
    title: "Переміщення",
    dataIndex: "diplacements",
    align: "diplacements",
    sorter: (a, b) => a.diplacements - b.diplacements,
  },
  {
    title: "Реалізації",
    dataIndex: "realization",
    key: "realizations",
    align: "center",
    sorter: (a, b) => a.realization - b.realization,
  },
  {
    title: "Перевірки цін",
    dataIndex: "checkPrice",
    key: "checkPrices",
    align: "center",
    sorter: (a, b) => a.checkPrice - b.checkPrice,
  },
  {
    title: "Перевірки документів",
    dataIndex: "verification",
    key: "verifications",
    align: "center",
    sorter: (a, b) => a.verification - b.verification,
    render: (_, record) => {
      return record?.verification;
    },
  },
];

export const getTableSummary = (statisticsData) => {
  const servicesArray = [
    {
      title: "realization",
      value: 0,
    },
    {
      title: "verification",
      value: 0,
    },
    {
      title: "revizia",
      value: 0,
    },
    {
      title: "diplacements",
      value: 0,
    },
    {
      title: "order",
      value: 0,
    },
    {
      title: "incoice",
      value: 0,
    },
    {
      title: "returns",
      value: 0,
    },
    {
      title: "checkPrices",
      value: 0,
    },
  ];

  statisticsData.forEach((element) => {
    // delete element.username;

    const userServicesData = Object.keys(element);
    userServicesData.forEach((userService) => {
      const serviceValue = element[userService];
      servicesArray.find((serv) => {
        if (serv.title === userService) {
          serv.value = +serv.value + +serviceValue;
        }
      });
    });
  });

  return (
    <>
      <Table.Summary.Row
        style={{
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        <Table.Summary.Cell index={0}>Всього</Table.Summary.Cell>
        <Table.Summary.Cell index={1}>
          {servicesArray.find((service) => service.title === "revizia")?.value}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          {servicesArray.find((service) => service.title === "invoice")?.value}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3}>
          {servicesArray.find((service) => service.title === "order")?.value}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4}>
          {servicesArray.find((service) => service.title === "returns")?.value}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5}>
          {
            servicesArray.find((service) => service.title === "diplacements")
              ?.value
          }
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6}>
          {
            servicesArray.find((service) => service.title === "realization")
              ?.value
          }
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7}>
          {
            servicesArray.find((service) => service.title === "checkPrice")
              ?.value
          }
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3}>
          {
            servicesArray.find((service) => service.title === "verification")
              ?.value
          }
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};
