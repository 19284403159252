import { getServer } from "../../utils/Environment";

const server = getServer();

export async function removePayment(paymentId, companyId) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      paymentId,
      companyId,
      method: "remove-company-payment",
    }),
  });
  return await responce.json();
}

export async function updateUserPaymentSum(sum, userId) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      sum,
      userId,
      method: "update-user-payment-sum",
    }),
  });
  return await responce.json();
}

export const addCompanyPayment = async (
  companyId,
  sum,
  date,
  paymentDueDate,
  comment,
  paymentId
) => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      companyId,
      sum,
      date,
      paymentDueDate,
      comment,
      paymentId,
      method: "add-company-payment",
    }),
  });
  return await responce.json();
};

// export const getPayments = async (id) => {
//   const responce = await fetch(server + "/server/server.php", {
//     method: "POST",
//     body: JSON.stringify({ method: "get-user-payments" }),
//   });
//   return await responce.json();
// };
