import { useEffect, useState } from "react";
import { getStatistics } from "../components/api/db-api";
import { Form, Tabs } from "antd";

import DaylyStatistics from "../components/statistics/DaylyStatistics";
import StatisticsByDate from "../components/statistics/StatisticsByDate";

export default function WaappStatistics() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [statisticsData, setStatisticsData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const statistics = await getStatistics();
    setStatisticsData(statistics);
    setLoading(false);
  };

  const items = [
    {
      key: "1",
      label: `Сьогодні`,
      children: (
        <DaylyStatistics
          statisticsData={statisticsData}
          loading={loading}
        ></DaylyStatistics>
      ),
    },
    {
      key: "2",
      label: `За період`,
      children: <StatisticsByDate></StatisticsByDate>,
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="1" items={items}></Tabs>
    </>
  );
}
