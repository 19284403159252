import { Button, Input, Space, Table } from "antd";
import { useRef, useState } from "react";
import dayjs from "dayjs";
import {
  MobileOutlined,
  UserOutlined,
  SearchOutlined,
  MobileFilled,
  CalendarOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ModalActions from "./ModalActions";
import ModalEditTa from "./ModalEditTa";
import ModalCreateTa from "./ModalCreateTa";

export default function AgentsTable(props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [user, setUser] = useState();
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const [setSearchText] = useState("");
  const searchInput = useRef(null);
  const [setSelectedUsers] = useState([]);
  const [isModalEditTAOpen, setIsModalEditTAOpen] = useState(false);
  const [isModalCreateTAOpen, setIsModalCreateTAOpen] = useState(false);
  const navigate = useNavigate();
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size={"large"}
          ref={searchInput}
          placeholder={`Введіть текст`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Шукати
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Очистити
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const onRowAction = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setIsModalActionsOpen(true);
        setUser(record);
      },
    };
  };

  const columns = [
    { title: "№", key: "№", dataIndex: "key" },
    {
      title: <UserOutlined />,
      dataIndex: "login",
      key: "login",
      align: "center",
      sorter: (a, b) => {
        return a.login.localeCompare(b.login);
      },
      ...getColumnSearchProps("login"),
    },
    {
      title: <CalendarOutlined />,
      dataIndex: "expiration_date",
      key: "expiration_date",
      align: "center",
      render: (_, record) => {
        const date = dayjs(record.expiration_date).format("DD.MM.YYYY");
        return date;
      },
      sorter: (a, b) =>
        new Date(a.expiration_date) - new Date(b.expiration_date),
    },
    {
      title: "Компанія",
      dataIndex: "company",
      key: "company",
      align: "center",
      ...getColumnSearchProps("company"),
    },
    {
      title: <MobileOutlined />,
      dataIndex: "num_devices",
      align: "center",
      sorter: (a, b) => a.num_devices - b.num_devices,
    },
    {
      title: <MobileFilled />,
      dataIndex: "used_devices",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.used_devices - b.used_devices,
    },
    {
      title: <CalendarOutlined />,
      dataIndex: "tokenCreated",
      align: "center",
      render: (_, record) => {
        const isValidDate = dayjs(record.tokenCreated).isValid();
        return isValidDate
          ? dayjs(record.tokenCreated).format("DD.MM.YYYY HH:MM:ss")
          : "";
      },
      sorter: (a, b) => new Date(a.tokenCreated) - new Date(b.tokenCreated),
    },
  ];

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedUsers(
      selectedRows.map((user) => ({ id: user.id, login: user.login }))
    );
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Button
        onClick={() => {
          setIsModalCreateTAOpen(true);
        }}
      >
        <UserAddOutlined></UserAddOutlined>
        Створити торгового
      </Button>
      <Table
        loading={props.loading}
        onRow={onRowAction}
        rowSelection={rowSelection}
        bordered={true}
        pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
        dataSource={props.tradeAgents}
        columns={columns}
      ></Table>
      <ModalActions
        isModalActionsOpen={isModalActionsOpen}
        setIsModalActionsOpen={setIsModalActionsOpen}
        user={user}
        users={props.tradeAgents}
        setUsers={props.setTradeAgents}
        setIsModalEditTAOpen={setIsModalEditTAOpen}
      ></ModalActions>
      <ModalEditTa
        user={user}
        companies={props.companies}
        setTradeAgents={props.setTradeAgents}
        isModalEditTAOpen={isModalEditTAOpen}
        setIsModalEditTAOpen={setIsModalEditTAOpen}
      ></ModalEditTa>
      <ModalCreateTa
        companies={props.companies}
        setTradeAgents={props.setTradeAgents}
        isModalCreateTAOpen={isModalCreateTAOpen}
        setIsModalCreateTAOpen={setIsModalCreateTAOpen}
      ></ModalCreateTa>
    </>
  );
}
