import { Modal } from "antd";
import AddPaymentForm from "./UpsertPaymentForm";

export default function ModalEdit({
  payments,
  setPayments,
  companies,
  isModalOpen,
  setIsModalOpen,
  selectedPay,
}) {
  return (
    <div>
      <Modal
        title={"Редагування оплати"}
        open={isModalOpen}
        styles={{ header: { textAlign: "center" } }}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        width={"90%"}
      >
        <AddPaymentForm
          payments={payments}
          setPayments={setPayments}
          companies={companies}
          selectedPay={selectedPay}
          setIsModalOpen={setIsModalOpen}
        ></AddPaymentForm>
      </Modal>
    </div>
  );
}
