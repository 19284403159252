import React, { useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import dayjs from "dayjs";
import { editTaUser } from "../api/db-api";

export default function EditTAForm({
  user,
  setTradeAgents,
  setIsModalEditTAOpen,
  companies,
}) {
  const onFinish = async (values: any) => {
    const newValues = {
      ...values,
      expirationDate: dayjs(values.expirationDate).add(1, "day"),
    };

    const result = await editTaUser(newValues);

    if (result?.status === "success") {
      message.success(result.message);
      setTradeAgents((prevUsers) => {
        return prevUsers.map((prevUser) =>
          prevUser.id === user.id
            ? {
                ...prevUser,
                expiration_date: newValues.expirationDate,
                num_devices: newValues.deviceCount,
                login: newValues.username,
                company: companies.find((comp) => comp.id === newValues.company)
                  .label,
              }
            : prevUser
        );
      });
      setIsModalEditTAOpen(false);
    } else {
      message.error("Помилка оновлення користувача. " + result?.message + "");
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      password: undefined,
      username: user.username,
      expirationDate: dayjs(user.expiration_date),
      deviceCount: user.num_devices,
      company: companies.find((comp) => user.company === comp.label).value,
      id: user.id,
    });
  }, [user]);

  return (
    <>
      <Row
        style={{
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <Form
          layout="horizontal"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ width: "100%" }}
          form={form}
        >
          <Col span={12}>
            <Form.Item
              label="Логін"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Пароль"
              name="password"
              rules={[
                {
                  // required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
            <Form.Item
              label="Компанія"
              name="company"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Select options={companies}></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              name="expirationDate"
              label="Доступ до"
            >
              <DatePicker />
            </Form.Item>

            <Form.Item name="deviceCount" label="К-ть пристроїв">
              <InputNumber min={1}></InputNumber>
            </Form.Item>
            <Form.Item
              label="id"
              name="id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit">
                Оновити
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
    </>
  );
}
