import { Modal, Tabs } from "antd";
import CreateTaForm from "./CreateTAForm";

interface ModalCreateUserProps {
  isModalCreateTAOpen: boolean;
  setIsModalCreateTAOpen: Function;
  setTradeAgents: Function;
  companies: [];
}

export default function ModalCreateTa({
  setTradeAgents,
  isModalCreateTAOpen,
  setIsModalCreateTAOpen,
  companies,
}: ModalCreateUserProps) {
  const items = [
    {
      key: "1",
      label: `Дані`,
      children: (
        <CreateTaForm
          setTradeAgents={setTradeAgents}
          setIsModalCreateTAOpen={setIsModalCreateTAOpen}
          companies={companies}
        ></CreateTaForm>
      ),
    },
  ];
  const handleCancel = () => {
    setIsModalCreateTAOpen(false);
  };

  return (
    <div>
      <Modal
        title={"Створення торгового"}
        open={isModalCreateTAOpen}
        styles={{ header: { textAlign: "center" } }}
        footer={null}
        onCancel={handleCancel}
        width={"90%"}
      >
        <Tabs defaultActiveKey="1" items={items}></Tabs>
      </Modal>
    </div>
  );
}
