import React, { useState } from "react";
import {
  Table,
  Input,
  Space,
  Button,
  message,
  Popconfirm,
  Checkbox,
  Divider,
  SpinProps,
} from "antd";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import {
  MobileOutlined,
  UserOutlined,
  SearchOutlined,
  MobileFilled,
  CalendarOutlined,
  DeleteOutlined,
  UserAddOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { ModalActions } from "./ModalActions";
import { DateFilterDropDown } from "../filters/DateFilterDropDown";
import { ModalChangeLicenseDate } from "./ModalChangeLicenseDate";
import { deleteUserSessionAPI } from "../api/db-api";
import ModalEditUser from "./ModalEditUser";
import ModalCheckIpBlock from "./ModalCheckIpBlock";
import type { TableColumnsType } from "antd";
import { User } from "../types";

interface DataType {
  key: React.Key;
  login: string;
  expiration_date: dayjs.Dayjs;
  active: boolean;
  ownFt: boolean;
  exchangeType: string;
  used_devices: number;
  sessions: [];
}

const handleSearch = (confirm: any) => {
  confirm();
};
const handleReset = (clearFilters: () => void, confirm: () => void) => {
  clearFilters();
  confirm();
};

const getColumnSearchProps = (dataIndex: any) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        size={"large"}
        placeholder={`Введіть текст`}
        autoFocus={true}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(confirm)}
        style={{
          marginBottom: 8,
          display: "block",
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Шукати
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters, confirm)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Очистити
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: any) => (
    <SearchOutlined
      style={{
        color: filtered ? "#1890ff" : undefined,
      }}
    />
  ),
  onFilter: (
    value: string,
    record: { [x: string]: { toString: () => string } }
  ) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
});

const columns: TableColumnsType<DataType> = [
  {
    title: "№",
    key: "key",
    dataIndex: "key",
    sorter: (a: { key: React.Key }, b: { key: React.Key }) => a.key - b.key,
  },
  {
    title: <UserOutlined />,
    dataIndex: "login",
    key: "login",
    align: "center",
    sorter: (a: { login: string }, b: { login: any }) => {
      return a.login.localeCompare(b.login);
    },
    ...getColumnSearchProps("login"),
  },
  {
    title: <CalendarOutlined />,
    dataIndex: "expiration_date",
    key: "expiration_date",
    align: "center",
    render: (
      _: any,
      record: {
        expiration_date:
          | string
          | number
          | Date
          | dayjs.Dayjs
          | null
          | undefined;
      }
    ) => {
      const date = dayjs(record.expiration_date).format("DD.MM.YYYY");
      return date;
    },
    sorter: (
      a: { expiration_date: string | number | Date },
      b: { expiration_date: string | number | Date }
    ) => new Date(a.expiration_date) - new Date(b.expiration_date),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <DateFilterDropDown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      );
    },
    filterIcon: () => {
      return <SearchOutlined></SearchOutlined>;
    },
    onFilter: (
      value: (string | number | Date | dayjs.Dayjs | null | undefined)[],
      record: {
        expiration_date:
          | string
          | number
          | Date
          | dayjs.Dayjs
          | null
          | undefined;
      }
    ) => {
      return record.expiration_date
        ? dayjs(record.expiration_date).isBefore(value[0], "day")
        : "";
    },
  },
  {
    title: "Компанія",
    dataIndex: "company",
    key: "company",
    align: "center",
    ...getColumnSearchProps("company"),
  },
  {
    title: <MobileOutlined />,
    key: "num_devices",
    dataIndex: "num_devices",
    align: "center",
    sorter: (a: { num_devices: number }, b: { num_devices: number }) =>
      a.num_devices - b.num_devices,
  },
  {
    title: <MobileFilled />,
    dataIndex: "used_devices",
    key: "used_devices",
    align: "center",
    defaultSortOrder: "descend",
    sorter: (
      a: { sessions: string | any[] },
      b: { sessions: string | any[] }
    ) => {
      const len1 = a?.sessions?.length ? a.sessions?.length : 0;
      const len2 = b?.sessions?.length ? b.sessions?.length : 0;
      return len1 - len2;
    },
    render: (_: any, record: { sessions: string | any[] }) => {
      return record.sessions?.length;
    },
    filters: [
      { text: "Є активні сесії", value: true },
      { text: "Немає", value: false },
    ],
    onFilter: (value, record) => record.sessions?.length > 0 === value,
  },

  {
    title: "Тип обміну",
    dataIndex: "exchangeType",
    key: "exchangeType",
    align: "center",
    sorter: (a: { exchangeType: string }, b: { exchangeType: any }) =>
      a.exchangeType.localeCompare(b.exchangeType),
    filters: [
      { text: "XML", value: "xml" },
      { text: "TXT", value: "txt" },
    ],
    onFilter: (value, record) => record.exchangeType === value,
  },
  {
    title: "Інше FTP",
    dataIndex: "ownFtp",
    key: "ownFtp",
    align: "center",
    render: (_: any, record: { ownFtp: any }) => {
      return <Checkbox checked={record.ownFtp ? true : false}></Checkbox>;
    },
    filters: [
      { text: "Так", value: true },
      { text: "Ні", value: false },
    ],
    onFilter: (value, record) => record.ownFtp === value,
  },
  {
    title: "Активність",
    dataIndex: "active",
    key: "active",
    align: "center",
    render: (_: any, record) => {
      return (
        <Checkbox
          checked={
            dayjs(record.expiration_date).isBefore(dayjs(), "day")
              ? false
              : true
          }
        ></Checkbox>
      );
    },
    filters: [
      { text: "Активний", value: true },
      { text: "Неактивний", value: false },
    ],
    onFilter: (value, record) => {
      const isActive = !dayjs(record.expiration_date).isBefore(dayjs(), "day");
      return isActive === value;
    },
  },
];

const defaultCheckedList = localStorage.getItem("defaultCheckedList")
  ? localStorage.getItem("defaultCheckedList")?.split(",")
  : columns.map((item) => item.key);

export default function UsersTable(props: {
  users: readonly any[] | undefined;
  loading: boolean | SpinProps | undefined;
  setUsers: any;
}) {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [user, setUser] = useState<User>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const [isModalEditUserOpen, setIsModalEditUserOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isModalChangeLicenseDateOpen, setIsModalChangeLicenseDateOpen] =
    useState(false);
  const [isModalCheckIpBlockOpen, setIsModalCheckIpBlockOpen] = useState(false);
  const [activeUserSessions, setActiveUserSessions] = useState<any>();
  const [checkedList, setCheckedList] = useState<any>(defaultCheckedList);

  const options = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const newColumns = columns.map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key),
  }));

  const deleteUserSession = async (record: {
    user_id: string | number;
    session_id: any;
  }) => {
    const result = await deleteUserSessionAPI(
      +record.user_id,
      record.session_id
    );
    if (!result) return message.error("Помилка видалення сесії");

    const newUserSessions = activeUserSessions?.filter(
      (session: { session_id: any }) => session.session_id !== record.session_id
    );
    setActiveUserSessions(newUserSessions);

    if (!newUserSessions.length) {
      // setExpandedRowKeys([]);
    }

    const currentUser = props.users?.find(
      (user: { id: number }) => user.id === +record.user_id
    );
    currentUser.sessions = newUserSessions;
  };

  const expandedRowRender = (row: any) => {
    const childColumns = [
      {
        title: "Ід сесії",
        dataIndex: "session_id",
        width: "20%",
        key: "session_id",
      },
      {
        title: "Час створення",
        dataIndex: "login_time",
        key: "login_time",
        width: "15%",
      },
      {
        title: "IP-адреса",
        dataIndex: "ip_address",
        key: "ip_address",
        width: "15%",
      },
      {
        title: "Агент",
        dataIndex: "user_agent",
        key: "user_agent",
      },
      {
        title: <DeleteOutlined />,
        key: "remove",
        dataIndex: "user_id",
        width: "10%",
        render: (_: any, record: any) => {
          return (
            <Popconfirm
              title={"Видалити?"}
              key={record.key}
              onConfirm={() => deleteUserSession(record)}
            >
              <DeleteOutlined />
            </Popconfirm>
          );
        },
      },
    ];

    return (
      <Table
        columns={childColumns}
        dataSource={activeUserSessions}
        pagination={false}
        tableLayout="fixed"
      />
    );
  };

  const onRowAction = async (record: any) => {
    setUser(record);
    setIsModalActionsOpen(true);
  };

  const onTableRowExpand = (expanded: boolean, record: User) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
    setActiveUserSessions(
      record.sessions.map((session: { session_id: any }) => {
        return { ...session, key: session.session_id };
      })
    );
  };

  const onSelectChange = (
    newSelectedRowKeys: React.SetStateAction<never[]>,
    selectedRows: {
      map: (
        arg0: (user: any) => { id: any; login: any }
      ) => React.SetStateAction<never[]>;
    }
  ) => {
    setSelectedUsers(
      selectedRows.map((user: { id: any; login: any }) => ({
        id: user.id,
        login: user.login,
        key: user.id,
      }))
    );
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <br></br>
      <Button
        onClick={() => {
          navigate("add-user");
        }}
        type="primary"
      >
        <UserAddOutlined />
        Створити користувача
      </Button>
      <Button
        onClick={() => {
          if (!selectedUsers.length) {
            return message.error("Виберіть користувачів");
          }
          navigate("multiple-edit-users", { state: { users: selectedUsers } });
        }}
      >
        <EditOutlined />
        Масове редагування
      </Button>
      <Button
        onClick={() => {
          if (!selectedUsers.length) {
            return message.error("Виберіть користувачів");
          }
          setIsModalChangeLicenseDateOpen(true);
        }}
      >
        <CalendarOutlined />
        Змінити термін дії ліцензії
      </Button>
      <Button
        onClick={() => {
          setIsModalCheckIpBlockOpen(true);
        }}
      >
        <CheckOutlined />
        Перевірити блокування ІП
      </Button>
      <br></br>
      <br></br>
      <Checkbox.Group
        value={checkedList}
        options={options}
        onChange={(value) => {
          setCheckedList(value);
          localStorage.setItem("defaultCheckedList", value.toString());
        }}
      />
      <br></br>
      <br></br>
      <Table
        loading={props.loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onRowAction(record);
            },
          };
        }}
        rowSelection={rowSelection}
        columns={newColumns}
        dataSource={props.users}
        bordered={true}
        pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
        expandable={{
          expandedRowRender,
          expandRowByClick: false,
          onExpand: onTableRowExpand,
          expandedRowKeys,
          rowExpandable: (record) => record?.sessions,
        }}
      />

      {isModalEditUserOpen && (
        <ModalEditUser
          user={user}
          isModalEditUserOpen={isModalEditUserOpen}
          setIsModalEditUserOpen={setIsModalEditUserOpen}
          setUsers={props.setUsers}
        ></ModalEditUser>
      )}

      {isModalActionsOpen && (
        <ModalActions
          isModalActionsOpen={isModalActionsOpen}
          setIsModalActionsOpen={setIsModalActionsOpen}
          user={user}
          users={props.users}
          setUsers={props.setUsers}
          setIsModalEditUserOpen={setIsModalEditUserOpen}
        ></ModalActions>
      )}
      {isModalChangeLicenseDateOpen && (
        <ModalChangeLicenseDate
          isModalChangeLicenseDateOpen={isModalChangeLicenseDateOpen}
          setIsModalChangeLicenseDateOpen={setIsModalChangeLicenseDateOpen}
          users={selectedUsers}
        ></ModalChangeLicenseDate>
      )}
      {isModalCheckIpBlockOpen && (
        <ModalCheckIpBlock
          isModalCheckIpBlockOpen={isModalCheckIpBlockOpen}
          setIsModalCheckIpBlockOpen={setIsModalCheckIpBlockOpen}
        ></ModalCheckIpBlock>
      )}
    </>
  );
}
