import React from "react";
import { useNavigate } from "react-router";
import { Modal, List, message } from "antd";
import { clearUserDevices } from "../../components/api/db-api";

export const ModalActions = (props) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    props.setIsModalActionsOpen(false);
  };

  const handleMenuClick = async (e, record) => {
    switch (record.key) {
      case "clearDevices":
        const deleted = await clearUserDevices(props.user.id, props.user.login);
        // const res = deleted === "1" ? true : false;
        if (deleted) {
          message.success("Пристрої очищено!");
          let updatedUser = props.user;
          updatedUser.used_devices = 0;

          props.setUsers([...props.users], updatedUser);
        } else {
          message.error("Помилка очищення пристроїв");
        }
        props.setIsModalActionsOpen(false);
        break;
      case "edit":
        props.setIsModalActionsOpen(false);
        props.setIsModalEditTAOpen(true);
        break;
      case "delete":
        message.error("Так собі ідея");
        break;
      default:
        break;
    }
  };

  const data = [
    {
      title: "Змінити",
      key: "edit",
    },
    {
      title: "Очистити пристрої",
      key: "clearDevices",
    },
    {
      title: "Видалити",
      key: "delete",
    },
  ];

  return (
    <>
      <Modal
        title={"Користувач " + props.user?.login}
        style={{ textAlign: "center" }}
        open={props.isModalActionsOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item onClick={(e) => handleMenuClick(e, item)}>
              {item.title}
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};
export default ModalActions;
