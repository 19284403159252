import { useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Checkbox,
  message,
  Radio,
} from "antd";
import dayjs from "dayjs";
import { editUser } from "../api/db-api";
import { User } from "../../CONSTANTS/interfaces";
import { createFtpUser, editFtpUser } from "../api/hosting-api/functions";

interface EditFormProps {
  user: User;
  setUsers: Function;
  setIsModalEditUserOpen: Function;
}
export default function EditForm({
  user,
  setUsers,
  setIsModalEditUserOpen,
}: EditFormProps) {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const newValues = {
      ...values,
      expirationDate: dayjs(values.expirationDate).add(1, "day"),
    };
    const result = await editUser(newValues);
    if (result?.status === "success") {
      message.success("Успішно!");
      setUsers((prevUsers) => {
        return prevUsers.map((prevUser) =>
          prevUser.id === user.id
            ? {
                ...prevUser,
                expiration_date: values.expirationDate,
                num_devices: values.deviceCount,
                exchangeType: values.exchangeType,
                login: values.login,
              }
            : prevUser
        );
      });
      setIsModalEditUserOpen(false);
    } else {
      message.error("Помилка оновлення користувача. " + result?.message + "");
    }
  };
  const onFinishFailed = (errorInfo: object) => {
    console.log("Failed:", errorInfo);
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day").subtract(2, "days");
  };

  const upsertFtpUserAccount = async () => {
    const ftpLogin = form.getFieldValue("login");
    const password = form.getFieldValue("password");

    if (!ftpLogin || !password) {
      return message.error("Заповніть поля!");
    }
    const homedir = `waapp.com.ua/app/app-waapp-users/${ftpLogin}/`;

    let result;
    if (user.ftpUserId) {
      result = await editFtpUser(ftpLogin, password, homedir, user.ftpUserId);
    } else {
      result = await createFtpUser(ftpLogin, password, homedir);
    }

    if (!result) return message.error("Помилка виклику API для створення FTP");

    if (!result.result) {
      return message.error(result.messages.error);
    }
    message.success(
      result.messages[0] ? result.messages[0].success : result.messages.success
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      ipAddress: user.ipAddress,
      login: user.login,
      expirationDate: dayjs(user.expiration_date),
      deviceCount: user.num_devices,
      email: user.email,
      company: user.company,
      exchangeType: user.exchangeType,
      id: user.id,
      roles: [
        user?.services?.inventory ? "inventory" : "",
        user?.services?.invoice ? "invoice" : "",
        user?.services?.barcoding ? "barcoding" : "",
        user?.services?.checkPrice ? "checkPrice" : "",
        user?.services?.order ? "order" : "",
        user?.services?.perem ? "perem" : "",
        user?.services?.sales ? "sales" : "",
      ],
    });
  }, [user]);

  return (
    <>
      <Row justify={"center"}>
        <Form
          layout="vertical"
          name="editForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ justifyContent: "center" }}
          wrapperCol={{
            span: 20,
          }}
          form={form}
        >
          <Row
            style={{
              // textAlign: "center",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            <Col span={8}>
              <Form.Item
                label="Логін"
                name="login"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input maxLength={10} />
              </Form.Item>
              <Form.Item
                label="Пароль"
                name="password"
                rules={[
                  {
                    // required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
              <Form.Item
                label="Компанія"
                name="company"
                rules={[
                  {
                    required: true,
                    message: "Введіть компанію",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="id"
                name="id"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Іп адреса:порт"
                name="ipAddress"
                rules={[
                  {
                    required: false,
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="expirationDate"
                label="Доступ до"
              >
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  {
                    required: false,
                    message: "Please input your username!",
                    type: "email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="deviceCount" label="К-ть пристроїв">
                <InputNumber min={1}></InputNumber>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="roles" label="Доступні ролі">
                <Checkbox.Group>
                  <Row>
                    <Col span={24}>
                      <Checkbox value="inventory">Ревізія</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="invoice">Прихід</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="perem">Переміщення</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="order">Замовлення</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="sales">Реалізація</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="checkPrice">Перевірка цін</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="barcoding">Штрихкодування</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item name="exchangeType">
                <Radio.Group>
                  <Radio value={"xml"}>XML</Radio>
                  <Radio value={"txt"}>TXT</Radio>
                </Radio.Group>
              </Form.Item>
              <Button onClick={upsertFtpUserAccount}>
                {user.ftpUserId
                  ? "Змінити користувача FTP"
                  : "Створити користувача FTP"}
              </Button>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  Оновити
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
}
